import React from "react";
import theme from "theme";
import { Theme, Text, Box, Icon, LinkBox, Section, Link, Strong } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { Override } from "@quarkly/components";
import * as Components from "components";
import { MdArrowDownward } from "react-icons/md";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"szolgaltatasok"} />
		<Helmet>
			<title>
				Sikeres Kommunikáció - Szolgáltatásaink
			</title>
			<meta name={"description"} content={"Ismerd fel a másokban mélyen rejlő érzelmeket, elrejtett félelmeket, bizonytalanságokat és igényeket, mindezt akár hat percen belül."} />
			<meta property={"og:description"} content={"Ismerd fel a másokban mélyen rejlő érzelmeket, elrejtett félelmeket, bizonytalanságokat és igényeket, mindezt akár hat percen belül."} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/655c9b91867c86001f18116f/images/small_logo_favic.png?v=2023-11-27T21:50:13.746Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/655c9b91867c86001f18116f/images/small_logo_favic.png?v=2023-11-27T21:50:13.746Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/655c9b91867c86001f18116f/images/small_logo_favic.png?v=2023-11-27T21:50:13.746Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/655c9b91867c86001f18116f/images/small_logo_favic.png?v=2023-11-27T21:50:13.746Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/655c9b91867c86001f18116f/images/small_logo_favic.png?v=2023-11-27T21:50:13.746Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/655c9b91867c86001f18116f/images/small_logo_favic.png?v=2023-11-27T21:50:13.746Z"} />
		</Helmet>
		<Components.GlobalHeaderNavBar overflow-x="hidden" overflow-y="hidden" />
		<Section
			background="linear-gradient(0deg,rgba(22, 38, 63, 0.61) 0%,rgba(22, 38, 63, 0.65) 21.4%),--color-darkL2 url(https://uploads.quarkly.io/655c9b91867c86001f18116f/images/brainusb.jpg?v=2023-12-14T13:38:45.779Z) center/cover"
			padding="60px 0"
			sm-padding="40px 0"
			color="--light"
			font="--base"
			overflow-x="hidden"
			overflow-y="hidden"
			quarkly-title="Hero"
		>
			<Box margin="-16px -16px -16px -16px" display="flex">
				<Box display="flex" padding="16px 16px 16px 16px" width="75%" md-width="100%">
					<Box display="flex" flex-direction="column">
						<Text color="--lightD2" letter-spacing="1px" text-transform="uppercase" margin="0">
							kommunikálj tűpontosan
						</Text>
						<Text
							as="h1"
							font="--lead"
							font-size="3em"
							md-font="--headline2"
							margin="10px 0"
						>
							Csatlakozz a kommunikátorok felső 0,1%-hoz, és szerezz szuperképességeket!
						</Text>
					</Box>
				</Box>
			</Box>
			<LinkBox
				text-align="center"
				margin="96px 0 0 0"
				font="--base"
				color="--brandLight"
				href="#title"
			>
				<Text margin="8px 0" text-transform="uppercase">
					Szuperképességek
				</Text>
				<Icon category="md" margin="0 auto" icon={MdArrowDownward} />
			</LinkBox>
		</Section>
		<Section
			padding="140px 0"
			sm-padding="40px 0 40px 0"
			background="linear-gradient(0deg,rgba(72, 124, 207, 0.25) 0%,rgba(72, 124, 207, 0.15) 100%),url(https://uploads.quarkly.io/655c9b91867c86001f18116f/images/bikerbg.jpg?v=2023-12-14T12:51:05.756Z) left/cover"
			quarkly-title="Outro"
			overflow-x="hidden"
			overflow-y="hidden"
			sm-background="url(https://uploads.quarkly.io/655c9b91867c86001f18116f/images/bikerbg.jpg?v=2023-12-14T12:51:05.756Z) center/cover"
		>
			<Override slot="SectionContent" sm-align-items="center" />
			<Box
				max-width="360px"
				padding="50px 80px 80px 50px"
				background="--color-light"
				color="--dark"
				opacity="0.9"
			>
				<Text as="h2" font="--headline2" margin="0 0 12px 0" color="--brandPrimary">
					Ki mögé ülnél fel motorozni?
				</Text>
				<Text font="--base" color="--brandPrimary" opacity="0.8">
					Aki az elmúlt húsz évben az összes könyvet elolvasta arról, hogyan kell motorozni, vagy aki az elmúlt húsz évben motorozott?
					<br />
					<br />
					Amikor éles helyzetekről van szó, egyikünket sem érdekli a pályakezdő sebész falon lógó diplomája és hogy hány könyvet olvasott el a sebészetről. Sokkal inkább az, hogy hány sikeres műtéten van már túl, azaz milyen gyakorlata van. Senki sem kéri számon Gordon Ramsay szakács végzettségét, hiszen a főztjei minősége önmagukért beszélnek.
					<br />
					<br />
					Most már te is látod mekkora különbség van információ, és képesség között. A speciális katonai műveletekben a tudás önmagában semmit sem jelent. A tudás precíz alkalmazása jelenti a különbséget élet és halál között.
					<br />
					<br />
					És te pontosan ezért vagy itt: nem információra van szükséged, hanem a való életben is alkalmazható, kifinomult képességekre.
				</Text>
			</Box>
		</Section>
		<Section overflow-x="hidden" overflow-y="hidden">
			<Override slot="SectionContent" id="title" />
			<Text
				margin="60px 0px 0px 0px"
				text-align="center"
				font="--headline1"
				font-size="3em"
				color="--brandPrimary"
				opacity="0.8"
				md-font="normal 900 3em/1.2 --fontFamily-googleLexend"
				sm-font="normal 900 1.7em/1.2 --fontFamily-googleLexend"
			>
				SZOLGÁLTATÁSAINK
			</Text>
		</Section>
		<Section
			lg-padding="60px 0 60px 0"
			quarkly-title="Services"
			justify-content="center"
			padding="100px 0 30px 0"
			overflow-x="hidden"
			overflow-y="hidden"
		>
			<Override
				slot="SectionContent"
				width="100%"
				md-margin="0px 16px 0px 16px"
				min-width="auto"
				margin="0px 32px 0px 32px"
				id="content"
			/>
			<Box
				grid-gap="32px"
				grid-template-columns="2fr 3fr"
				lg-grid-template-columns="1fr"
				display="grid"
				margin="0 0 50px 0"
				background="--color-brandLight"
				border-radius="5px"
				min-height="540px"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					padding="15px 15px 15px 15px"
					align-items="flex-start"
					justify-content="space-between"
				>
					<Text
						margin="0px 0px 10px 0px"
						color="--dark"
						opacity="0.6"
						lg-text-align="center"
						text-transform="uppercase"
						font="--base"
						letter-spacing="1px"
						lg-margin="0px 0px 6px 0px"
						quarkly-title="Title"
					>
						oktatási platform - 19.99 GBP/hó | ~ 9 650huf
					</Text>
					<Text
						color="--brandPrimary"
						lg-text-align="center"
						md-margin="0px 0px 16px 0px"
						md-font="--headline3"
						font="--headline2"
						margin="0px 0px 28px 0px"
					>
						Sikeres Kommunikáció Akadémia
					</Text>
					<Text
						md-font="--base"
						font="--lead"
						margin="0px 0px 20px 0px"
						color="--brandPrimary"
						opacity="0.6"
						lg-text-align="left"
					>
						Küldetésünk, hogy képzéseink segítségével olyan kivételes képességekre tegyél szert, melyet manapság a titkosszolgálat alkalmaz: kommunikálj, győzz meg másokat és értelmezd az emberi viselkedést úgy, mint senki más rajtad kívül a szobában!
					</Text>
					<Link
						hover-transform="translateY(-4px)"
						transition="transform --transitionDuration-fast --transitionTimingFunction-easeInOut 0s"
						color="--light"
						padding="8px 18px 8px 18px"
						font="--lead"
						letter-spacing="0.5px"
						text-align="center"
						href="https://edu.sziklaybence.com"
						text-decoration-line="initial"
						background="--color-primary"
						target="_blank"
					>
						Csatlakozz itt
					</Link>
				</Box>
				<LinkBox
					transition="background --transitionDuration-normal --transitionTimingFunction-easeInOut 0s"
					lg-order="-1"
					width="100%"
					background="linear-gradient(0deg,rgba(22, 38, 63, 0.8) 0%,rgba(22, 38, 63, 0.8) 100%),--color-lightD2 url(https://uploads.quarkly.io/655c9b91867c86001f18116f/images/ska-service-bg.webp?v=2024-03-15T12:57:55.996Z) center/cover no-repeat"
					sm-border-radius="5px 5px 0px 0px"
					md-border-radius="5px 5px 0 0"
					justify-items="center"
					flex-direction="column"
					justify-content="center"
					quarkly-title="RightSideBox"
					target="_blank"
					href="https://edu.sziklaybence.com"
				>
					<Box
						min-width="100px"
						min-height="350px"
						font="normal 600 3em/1.2 --fontFamily-googleLexend"
						text-align="center"
						display="grid"
						align-items="center"
						justify-items="center"
						height="100%"
						sm-font="normal 600 2.5em/1.2 --fontFamily-googleLexend"
					>
						<Box min-width="100px" min-height="100px">
							<Text margin="0px 0px 28px 0px" color="--brandLight" lg-text-align="center" md-margin="0px 0px 16px 0px">
								Sikeres Kommunikáció
							</Text>
							<Text margin="0px 0px 28px 0px" color="--brandCta" lg-text-align="center" md-margin="0px 0px 16px 0px">
								Akadémia
							</Text>
						</Box>
						<Box
							background="rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/655c9b91867c86001f18116f/images/brandlight-logo.webp?v=2024-03-15T13:01:38.783Z) center/contain no-repeat scroll padding-box"
							width="40%"
							min-width="100px"
							min-height="50px"
							opacity="0.8"
						/>
					</Box>
				</LinkBox>
			</Box>
			<Box
				grid-gap="32px"
				grid-template-columns="2fr 3fr"
				lg-grid-template-columns="1fr"
				display="none"
				margin="0 0 50px 0"
				background="--color-brandLight"
				border-radius="5px"
				min-height="540px"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
					padding="15px 15px 15px 15px"
					justify-content="space-between"
				>
					<Text
						margin="0px 0px 10px 0px"
						color="--dark"
						opacity="0.6"
						lg-text-align="center"
						text-transform="uppercase"
						font="--base"
						letter-spacing="1px"
						lg-margin="0px 0px 6px 0px"
						quarkly-title="Title"
					>
						online kurzus - 28 960 Ft
					</Text>
					<Text
						color="--brandPrimary"
						lg-text-align="center"
						md-margin="0px 0px 16px 0px"
						md-font="--headline3"
						font="--headline2"
						margin="0px 0px 28px 0px"
					>
						Sikeres Munkahelyi Kommunikáció
					</Text>
					<Text
						md-font="--base"
						font="--lead"
						margin="0px 0px 20px 0px"
						color="--brandPrimary"
						opacity="0.6"
						lg-text-align="left"
					>
						Lépj túl az általános tanácsokon és sajátítsd el azokat a konkrét forgatókönyveket és technikákat, amik működnek. Tanuld meg hatékonyan kifejezni magadat és képviseld az érdekeidet határozottan, mégis elegánsan és erőszakmentesen. Alkalmazz taktikai kérdéseket, melyekkel kifinomultan tudod irányítani a beszélgetéseket, hogy bármilyen nehéz helyzetben könnyen érvényesülj.{" "}
					</Text>
					<Link
						hover-transform="translateY(-4px)"
						transition="transform --transitionDuration-fast --transitionTimingFunction-easeInOut 0s"
						color="--light"
						padding="8px 18px 8px 18px"
						font="--lead"
						letter-spacing="0.5px"
						text-align="center"
						href="../sikeres-munkahelyi-kommunikacio"
						text-decoration-line="initial"
						background="--color-primary"
					>
						Bővebben
					</Link>
				</Box>
				<Box
					transition="background --transitionDuration-normal --transitionTimingFunction-easeInOut 0s"
					lg-order="-1"
					width="100%"
					background="linear-gradient(0deg,rgba(22, 38, 63, 0.8) 0%,rgba(22, 38, 63, 0.8) 100%),--color-lightD2 url(https://uploads.quarkly.io/655c9b91867c86001f18116f/images/sikeres-munk-komm-serv-bg.webp?v=2024-03-15T12:57:55.901Z) center/cover no-repeat"
					sm-border-radius="5px 5px 0px 0px"
					md-border-radius="5px 5px 0 0"
					justify-items="center"
					flex-direction="column"
					justify-content="center"
					quarkly-title="RightSideBox"
				>
					<Box
						min-width="100px"
						min-height="350px"
						font="normal 600 3em/1.2 --fontFamily-googleLexend"
						text-align="center"
						display="grid"
						align-items="center"
						justify-items="center"
						height="100%"
						sm-font="normal 600 2.5em/1.2 --fontFamily-googleLexend"
					>
						<Box min-width="100px" min-height="100px">
							<Text margin="0px 0px 28px 0px" color="--brandLight" lg-text-align="center" md-margin="0px 0px 16px 0px">
								Sikeres Munkahelyi
							</Text>
							<Text margin="0px 0px 28px 0px" color="--brandCta" lg-text-align="center" md-margin="0px 0px 16px 0px">
								Kommunikáció
							</Text>
						</Box>
						<Box
							background="rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/655c9b91867c86001f18116f/images/brandlight-logo.webp?v=2024-03-15T13:01:38.783Z) center/contain no-repeat scroll padding-box"
							width="40%"
							min-width="100px"
							min-height="50px"
							opacity="0.8"
						/>
					</Box>
				</Box>
			</Box>
			<Box
				display="grid"
				grid-gap="32px"
				grid-template-columns="2fr 3fr"
				margin="0 0 50px 0"
				lg-grid-template-columns="1fr"
				lg-margin="56px 0px 0px 0px"
				background="--color-brandLight"
				border-radius="5px"
				min-height="540px"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
					padding="15px 15px 15px 15px"
					justify-content="space-between"
				>
					<Text
						quarkly-title="Title"
						font="--base"
						color="--dark"
						lg-margin="0px 0px 6px 0px"
						lg-text-align="center"
						margin="0px 0px 10px 0px"
						letter-spacing="1px"
						opacity="0.6"
						text-transform="uppercase"
					>
						Online kurzus - díjmentes
					</Text>
					<Text
						margin="0px 0px 28px 0px"
						color="--brandPrimary"
						lg-text-align="center"
						md-margin="0px 0px 16px 0px"
						md-font="--headline3"
						font="--headline2"
					>
						Befolyásolás és Profilozás Alapkurzus
					</Text>
					<Text
						opacity="0.6"
						lg-text-align="left"
						md-font="--base"
						font="--lead"
						margin="0px 0px 20px 0px"
						color="--brandPrimary"
					>
						Mivel megbízol bennünk és csatlakozol az Akadémiához, szeretnénk díjmentesen elérhetővé tenni számodra ezt a különleges képzést. Ez a tréning nem csak arra szolgál, hogy új szintre emelje emberismereti és kommunikációs készségeidet, hanem arra is, hogy teljesen új perspektívát nyisson meg előtted az emberi kapcsolatok és a világ működésének megértésében. Alig várjuk, hogy találkozzunk!
					</Text>
					<Link
						href="https://edu.sziklaybence.com/spaces/12760180?utm_source=website"
						color="--light"
						letter-spacing="0.5px"
						background="--color-primary"
						hover-transform="translateY(-4px)"
						transition="transform --transitionDuration-fast --transitionTimingFunction-easeInOut 0s"
						text-decoration-line="initial"
						padding="8px 18px 8px 18px"
						font="--lead"
						text-align="center"
						display="block"
					>
						Tovább az Akadémiára
					</Link>
				</Box>
				<LinkBox
					transition="background --transitionDuration-normal --transitionTimingFunction-easeInOut 0s"
					lg-order="-1"
					width="100%"
					background="linear-gradient(0deg,rgba(22, 38, 63, 0.8) 0%,rgba(22, 38, 63, 0.8) 100%),--color-lightD2 url(https://uploads.quarkly.io/655c9b91867c86001f18116f/images/befolyasolas_alapkurzus_serv-bg.webp?v=2024-03-15T12:57:55.903Z) center/cover no-repeat"
					sm-border-radius="5px 5px 0px 0px"
					md-border-radius="5px 5px 0 0"
					justify-items="center"
					flex-direction="column"
					justify-content="center"
					quarkly-title="RightSideBox"
					href="https://edu.sziklaybence.com/spaces/12760180?utm_source=website"
					target="_blank"
				>
					<Box
						min-width="100px"
						min-height="350px"
						font="normal 600 3em/1.2 --fontFamily-googleLexend"
						text-align="center"
						display="grid"
						align-items="center"
						justify-items="center"
						height="100%"
						sm-font="normal 600 2.5em/1.2 --fontFamily-googleLexend"
					>
						<Box min-width="100px" min-height="100px">
							<Text margin="0px 0px 28px 0px" color="--brandLight" lg-text-align="center" md-margin="0px 0px 16px 0px">
								Befolyásolás és Profilozás
							</Text>
							<Text margin="0px 0px 28px 0px" color="--brandCta" lg-text-align="center" md-margin="0px 0px 16px 0px">
								Alapkurzus
							</Text>
						</Box>
						<Box
							background="rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/655c9b91867c86001f18116f/images/brandlight-logo.webp?v=2024-03-15T13:01:38.783Z) center/contain no-repeat scroll padding-box"
							width="40%"
							min-width="100px"
							min-height="50px"
							opacity="0.8"
						/>
					</Box>
				</LinkBox>
			</Box>
			<Box
				display="grid"
				grid-gap="32px"
				grid-template-columns="2fr 3fr"
				margin="0 0 50px 0"
				lg-grid-template-columns="1fr"
				lg-margin="56px 0px 0px 0px"
				background="--color-brandLight"
				border-radius="5px"
				min-height="540px"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
					padding="15px 15px 15px 15px"
					justify-content="space-between"
				>
					<Text
						letter-spacing="1px"
						opacity="0.6"
						quarkly-title="Title"
						lg-margin="0px 0px 6px 0px"
						text-transform="uppercase"
						font="--base"
						margin="0px 0px 10px 0px"
						color="--dark"
						lg-text-align="center"
					>
						Könyv - 19 900 HUF
					</Text>
					<Text
						md-font="--headline3"
						font="--headline2"
						margin="0px 0px 28px 0px"
						color="--brandPrimary"
						lg-text-align="center"
						md-margin="0px 0px 16px 0px"
					>
						Ellipszis Kézikönyv
					</Text>
					<Text
						lg-text-align="left"
						md-font="--base"
						font="--lead"
						margin="0px 0px 20px 0px"
						color="--brandPrimary"
						opacity="0.6"
					>
						Csak erős idegzetűeknek! A legmodernebb, katonai szintű profilalkotási és befolyásolási technikák kézikönyvét tartod majd a kezedben, amelyet a viselkedés-programozás „Bibliájaként” is emlegetnek. A szerző{" "}
						<Strong>
							Chase Hughes
						</Strong>
						, a világ első számú profilozója és befolyásolás szakértője, aki eredetileg az amerikai hírszerzési műveletek számára írta ezt a korszakalkotó művet. Mi most elhoztunk neked magyar nyelven, hogy könnyeben befogadhasd! Figyelem! A könyv csak korlátozott számban elérhető: rendeld meg, amíg a készlet tart!
					</Text>
					<Link
						background="--color-primary"
						text-decoration-line="initial"
						text-align="center"
						padding="8px 18px 8px 18px"
						font="--lead"
						letter-spacing="0.5px"
						transition="transform --transitionDuration-fast --transitionTimingFunction-easeInOut 0s"
						hover-transform="translateY(-4px)"
						href="https://shop.sziklaybence.com/products/ellipszis-kezikonyv"
						color="--light"
						target="_blank"
					>
						Megveszem
					</Link>
				</Box>
				<LinkBox
					transition="background --transitionDuration-normal --transitionTimingFunction-easeInOut 0s"
					lg-order="-1"
					width="100%"
					background="--color-lightD2 url(https://uploads.quarkly.io/655c9b91867c86001f18116f/images/V-ELLIPSZIS-bor.jpg?v=2024-11-25T23:12:06.484Z) center center/cover no-repeat"
					sm-border-radius="5px 5px 0px 0px"
					md-border-radius="5px 5px 0 0"
					justify-items="center"
					flex-direction="column"
					justify-content="center"
					quarkly-title="RightSideBox"
					href="https://shop.sziklaybence.com/products/ellipszis-kezikonyv"
					target="_blank"
				>
					<Box
						min-width="100px"
						min-height="350px"
						font="normal 600 3em/1.2 --fontFamily-googleLexend"
						text-align="center"
						display="grid"
						align-items="center"
						justify-items="center"
						height="100%"
						sm-font="normal 600 2.5em/1.2 --fontFamily-googleLexend"
					/>
				</LinkBox>
			</Box>
			<Box
				display="grid"
				grid-gap="32px"
				grid-template-columns="2fr 3fr"
				margin="0 0 50px 0"
				lg-grid-template-columns="1fr"
				lg-margin="56px 0px 0px 0px"
				background="--color-brandLight"
				border-radius="5px"
				min-height="540px"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
					padding="15px 15px 15px 15px"
					justify-content="space-between"
				>
					<Text
						letter-spacing="1px"
						opacity="0.6"
						quarkly-title="Title"
						lg-margin="0px 0px 6px 0px"
						text-transform="uppercase"
						font="--base"
						margin="0px 0px 10px 0px"
						color="--dark"
						lg-text-align="center"
					>
						Könyv - 19 900 HUF
					</Text>
					<Text
						md-font="--headline3"
						font="--headline2"
						margin="0px 0px 28px 0px"
						color="--brandPrimary"
						lg-text-align="center"
						md-margin="0px 0px 16px 0px"
					>
						6MX - 6 Perc Röntgen Profilozás
					</Text>
					<Text
						lg-text-align="left"
						md-font="--base"
						font="--lead"
						margin="0px 0px 20px 0px"
						color="--brandPrimary"
						opacity="0.6"
					>
						Kevesebb mint hat perc alatt többet fogsz tudni arról a személyről, akivel beszélsz, mint a saját barátai és családja - nem vicc!
						<br />
						<br />
						A 6 Perc Röntgen Profilozás™ című könyvben megismerheted a világ leghatásosabb profilozó rendszerét, amelyet a CIA is használ. A szerző{" "}
						<Strong>
							Chase Hughes
						</Strong>
						, a világ vezető profilozója és viselkedés-befolyásolási szakértője, aki eredetileg az amerikai hírszerzési műveletek számára írta ezt a korszakalkotó művet.{" "}
					</Text>
					<Link
						background="--color-primary"
						text-decoration-line="initial"
						text-align="center"
						padding="8px 18px 8px 18px"
						font="--lead"
						letter-spacing="0.5px"
						transition="transform --transitionDuration-fast --transitionTimingFunction-easeInOut 0s"
						hover-transform="translateY(-4px)"
						href="https://shop.sziklaybence.com/products/6mx-6-perc-rontgen-profilozas"
						color="--light"
						target="_blank"
					>
						Megveszem
					</Link>
				</Box>
				<LinkBox
					transition="background --transitionDuration-normal --transitionTimingFunction-easeInOut 0s"
					lg-order="-1"
					width="100%"
					background="--color-lightD2 url(https://uploads.quarkly.io/655c9b91867c86001f18116f/images/V-6MX-bor.jpg?v=2024-11-25T23:12:06.478Z) center center/cover no-repeat"
					sm-border-radius="5px 5px 0px 0px"
					md-border-radius="5px 5px 0 0"
					justify-items="center"
					flex-direction="column"
					justify-content="center"
					quarkly-title="RightSideBox"
					href="https://shop.sziklaybence.com/products/6mx-6-perc-rontgen-profilozas"
					target="_blank"
				>
					<Box
						min-width="100px"
						min-height="350px"
						font="normal 600 3em/1.2 --fontFamily-googleLexend"
						text-align="center"
						display="grid"
						align-items="center"
						justify-items="center"
						height="100%"
						sm-font="normal 600 2.5em/1.2 --fontFamily-googleLexend"
					/>
				</LinkBox>
			</Box>
			<Box
				display="grid"
				grid-gap="32px"
				grid-template-columns="2fr 3fr"
				margin="0 0 50px 0"
				lg-grid-template-columns="1fr"
				lg-margin="56px 0px 0px 0px"
				background="--color-brandLight"
				border-radius="5px"
				min-height="540px"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
					padding="15px 15px 15px 15px"
					justify-content="space-between"
				>
					<Text
						letter-spacing="1px"
						opacity="0.6"
						quarkly-title="Title"
						lg-margin="0px 0px 6px 0px"
						text-transform="uppercase"
						font="--base"
						margin="0px 0px 10px 0px"
						color="--dark"
						lg-text-align="center"
					>
						E-Könyv - 14.75 GBP | ~ 6 500 HUF
					</Text>
					<Text
						md-font="--headline3"
						font="--headline2"
						margin="0px 0px 28px 0px"
						color="--brandPrimary"
						lg-text-align="center"
						md-margin="0px 0px 16px 0px"
					>
						Profilozó e-book
					</Text>
					<Text
						lg-text-align="left"
						md-font="--base"
						font="--lead"
						margin="0px 0px 20px 0px"
						color="--brandPrimary"
						opacity="0.6"
					>
						<Strong>
							Chase Hughes
						</Strong>
						{" "}viselkedéselemzési technikáira épülő kézikönyv, amely a profilozás művészetébe vezeti be az olvasót és rendkívüli betekintést nyújt az emberi viselkedés megértésébe. Ideális választás a 6MX Profilozó Kurzus mellé, illetve mindazok számára, akik szeretnének belemerülni a profilozás világába, és kiaknázni annak lehetőségeit a sikeres kommunikáció és emberi kapcsolatok területén.
					</Text>
					<Link
						background="--color-primary"
						text-decoration-line="initial"
						text-align="center"
						padding="8px 18px 8px 18px"
						font="--lead"
						letter-spacing="0.5px"
						transition="transform --transitionDuration-fast --transitionTimingFunction-easeInOut 0s"
						hover-transform="translateY(-4px)"
						href="https://shop.sziklaybence.com/products/profilozoi-kezikonyv?utm_source=copyToPasteBoard&utm_medium=product-links&utm_content=web"
						color="--light"
						target="_blank"
					>
						Megveszem
					</Link>
				</Box>
				<LinkBox
					transition="background --transitionDuration-normal --transitionTimingFunction-easeInOut 0s"
					lg-order="-1"
					width="100%"
					background="--color-lightD2 url(https://uploads.quarkly.io/655c9b91867c86001f18116f/images/profilozoi-kezikonyv-3.0.png?v=2025-02-01T14:00:43.152Z) top/cover no-repeat"
					sm-border-radius="5px 5px 0px 0px"
					md-border-radius="5px 5px 0 0"
					justify-items="center"
					flex-direction="column"
					justify-content="center"
					quarkly-title="RightSideBox"
					href="https://shop.sziklaybence.com/products/profilozoi-kezikonyv?utm_source=copyToPasteBoard&utm_medium=product-links&utm_content=web"
					target="_blank"
				>
					<Box
						min-width="100px"
						min-height="350px"
						font="normal 600 3em/1.2 --fontFamily-googleLexend"
						text-align="center"
						display="grid"
						align-items="center"
						justify-items="center"
						height="100%"
						sm-font="normal 600 2.5em/1.2 --fontFamily-googleLexend"
					/>
				</LinkBox>
			</Box>
			<Box
				display="grid"
				grid-gap="32px"
				grid-template-columns="2fr 3fr"
				margin="0 0 50px 0"
				lg-grid-template-columns="1fr"
				lg-margin="56px 0px 0px 0px"
				background="--color-brandLight"
				border-radius="5px"
				min-height="540px"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
					padding="15px 15px 15px 15px"
					justify-content="space-between"
				>
					<Text
						letter-spacing="1px"
						opacity="0.6"
						quarkly-title="Title"
						lg-margin="0px 0px 6px 0px"
						text-transform="uppercase"
						font="--base"
						margin="0px 0px 10px 0px"
						color="--dark"
						lg-text-align="center"
					>
						elemzés - 8.75 GBP-tól | ~3900 HUF-TÓL
					</Text>
					<Text
						md-font="--headline3"
						font="--headline2"
						margin="0px 0px 28px 0px"
						color="--brandPrimary"
						lg-text-align="center"
						md-margin="0px 0px 16px 0px"
					>
						Személyiségteszt
					</Text>
					<Text
						lg-text-align="left"
						md-font="--base"
						font="--lead"
						margin="0px 0px 20px 0px"
						color="--brandPrimary"
						opacity="0.6"
					>
						Profilozó tesztünk segítségével valós képet kapsz önmagadról! Megtudod milyen elkerülési és védekezési mechanizmusai vannak a karakterednek, milyen belső félelmek és vágyak hajtanak, valamint milyen előítéleteken és szűrőkön keresztül látod a világot. Részletes leírást kapsz a személyiségedről, melyben  praktikus tanácsokkal  segítünk a jellemed fejlesztésében, hogy boldogabb és fejlettebb szinten élj. Útmutatót adunk ahhoz, hogyan ismerd fel mások karakterét és formáld az üzeneteidet a személyiségükhöz, hogy a legjobb hatékonyságot érd el.
					</Text>
					<Link
						background="--color-primary"
						text-decoration-line="initial"
						text-align="center"
						padding="8px 18px 8px 18px"
						font="--lead"
						letter-spacing="0.5px"
						transition="transform --transitionDuration-fast --transitionTimingFunction-easeInOut 0s"
						hover-transform="translateY(-4px)"
						href="https://teszt.sziklaybence.com/"
						color="--light"
						target="_blank"
					>
						Ugrás a tesztre
					</Link>
				</Box>
				<LinkBox
					transition="background --transitionDuration-normal --transitionTimingFunction-easeInOut 0s"
					lg-order="-1"
					width="100%"
					sm-border-radius="5px 5px 0px 0px"
					md-border-radius="5px 5px 0 0"
					justify-items="center"
					flex-direction="column"
					justify-content="center"
					quarkly-title="RightSideBox"
					background="url(https://uploads.quarkly.io/655c9b91867c86001f18116f/images/creative1x1c.webp?v=2024-07-30T11:44:11.324Z) top/cover no-repeat"
					href="https://teszt.sziklaybence.com/"
					target="_blank"
				>
					<Box
						min-width="100px"
						min-height="350px"
						font="normal 600 3em/1.2 --fontFamily-googleLexend"
						text-align="center"
						display="grid"
						align-items="center"
						justify-items="center"
						height="100%"
						sm-min-height="250px"
						sm-font="normal 600 2.5em/1.2 --fontFamily-googleLexend"
					/>
				</LinkBox>
			</Box>
			<Box
				display="grid"
				grid-gap="32px"
				grid-template-columns="2fr 3fr"
				margin="0 0 50px 0"
				lg-grid-template-columns="1fr"
				lg-margin="56px 0px 0px 0px"
				background="--color-brandLight"
				border-radius="5px"
				min-height="540px"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
					padding="15px 15px 15px 15px"
					justify-content="space-between"
				>
					<Text
						letter-spacing="1px"
						opacity="0.6"
						quarkly-title="Title"
						lg-margin="0px 0px 6px 0px"
						text-transform="uppercase"
						font="--base"
						margin="0px 0px 10px 0px"
						color="--dark"
						lg-text-align="center"
					>
						online kurzus - 23.96 GBP-tól | ~11200 HUF
					</Text>
					<Text
						md-font="--headline3"
						font="--headline2"
						margin="0px 0px 28px 0px"
						color="--brandPrimary"
						lg-text-align="center"
						md-margin="0px 0px 16px 0px"
					>
						5M Kurzus - Mesterkulcs a sikerhez
					</Text>
					<Text
						lg-text-align="left"
						md-font="--base"
						font="--lead"
						margin="0px 0px 20px 0px"
						color="--brandPrimary"
						opacity="0.6"
					>
						Ez a program egy olyan élethack, amit mindannyian szerettünk volna kapni gyerekkorunkban a sok használhatatlan tudás helyett. Egy olyan eszköztárat kapsz a kezedbe, amely hatékonyan és sallang nélkül készít fel az élet valós problémáira, mindezt a pszichológia, kommunikáció és önfejlesztés lencséjén keresztül. Bármilyen helyzetben is vagy, az eszközök, amiket most kapsz, teljesen új perspektívát adnak arra, hogyan működik a világ - mindenekelőtt lehetővé teszik, hogy oly módon az emberi viselkedést és interakciókat, mint még soha. Szuperképességeket adunk a kezedbe, és ez csak a kezdet.
					</Text>
					<Link
						background="--color-primary"
						text-decoration-line="initial"
						text-align="center"
						padding="8px 18px 8px 18px"
						font="--lead"
						letter-spacing="0.5px"
						transition="transform --transitionDuration-fast --transitionTimingFunction-easeInOut 0s"
						hover-transform="translateY(-4px)"
						href="#"
						color="--light"
						display="none"
					>
						Lássuk!
					</Link>
				</Box>
				<Box
					transition="background --transitionDuration-normal --transitionTimingFunction-easeInOut 0s"
					lg-order="-1"
					width="100%"
					sm-border-radius="5px 5px 0px 0px"
					md-border-radius="5px 5px 0 0"
					justify-items="center"
					flex-direction="column"
					justify-content="center"
					quarkly-title="RightSideBox"
					background="url(https://uploads.quarkly.io/655c9b91867c86001f18116f/images/hamarosan.png?v=2023-12-14T13:45:38.514Z) top/contain no-repeat,#6d99cf linear-gradient(90deg,#6793ca 0%,#6a96cc 50.5%,#6e9ad0 100%)"
				>
					<Box
						min-width="100px"
						min-height="350px"
						font="normal 600 3em/1.2 --fontFamily-googleLexend"
						text-align="center"
						display="grid"
						align-items="center"
						justify-items="center"
						height="100%"
						sm-min-height="250px"
						sm-font="normal 600 2.5em/1.2 --fontFamily-googleLexend"
					/>
				</Box>
			</Box>
			<Box
				display="grid"
				grid-gap="32px"
				grid-template-columns="2fr 3fr"
				margin="0 0 50px 0"
				lg-grid-template-columns="1fr"
				background="--color-brandLight"
				border-radius="5px"
				min-height="540px"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
					padding="15px 15px 15px 15px"
					justify-content="space-between"
				>
					<Text
						letter-spacing="1px"
						opacity="0.6"
						quarkly-title="Title"
						lg-margin="0px 0px 6px 0px"
						text-transform="uppercase"
						font="--base"
						margin="0px 0px 10px 0px"
						color="--dark"
						lg-text-align="center"
					>
						Online kurzus - 23.96 GBP/hó | ~11200 HUF{" "}
					</Text>
					<Text
						md-font="--headline3"
						font="--headline2"
						margin="0px 0px 28px 0px"
						color="--brandPrimary"
						lg-text-align="center"
						md-margin="0px 0px 16px 0px"
					>
						6M Kurzus - Röntgen Profilozás
					</Text>
					<Text
						lg-text-align="left"
						md-font="--base"
						font="--lead"
						margin="0px 0px 20px 0px"
						color="--brandPrimary"
						opacity="0.6"
					>
						Az amerikai hírszerzésnél használt profilozással és befolyásolással kapcsolatos tudást “desztilláltuk”, akkreditáltuk és a civil életre szabtuk. Ez a program lépésről lépésre vezet végig azokon a technikákon, amelyekkel átlátsz a társadalmi álarcokon, szociális félelmeken, valamint megtalálod a döntések mögött rejlő valódi motivációt. Nincs mellébeszélés, csak részletes útmutató mindenről, amire szükséged van.
					</Text>
					<Link
						background="--color-primary"
						text-decoration-line="initial"
						text-align="center"
						padding="8px 18px 8px 18px"
						font="--lead"
						letter-spacing="0.5px"
						transition="transform --transitionDuration-fast --transitionTimingFunction-easeInOut 0s"
						hover-transform="translateY(-4px)"
						href="../6mx-profilozas"
						color="--light"
						display="none"
					>
						Bővebben
					</Link>
				</Box>
				<Box
					transition="background --transitionDuration-normal --transitionTimingFunction-easeInOut 0s"
					lg-order="-1"
					width="100%"
					sm-border-radius="5px 5px 0px 0px"
					md-border-radius="5px 5px 0 0"
					justify-items="center"
					flex-direction="column"
					justify-content="center"
					quarkly-title="RightSideBox"
					background="url(https://uploads.quarkly.io/655c9b91867c86001f18116f/images/hamarosan.png?v=2023-12-14T13:45:38.514Z) top/contain no-repeat,#6d99cf linear-gradient(90deg,#6793ca 0%,#6a96cc 50.5%,#6e9ad0 100%)"
				>
					<Box
						min-width="100px"
						min-height="350px"
						font="normal 600 3em/1.2 --fontFamily-googleLexend"
						text-align="center"
						display="grid"
						align-items="center"
						justify-items="center"
						height="100%"
						sm-min-height="250px"
						sm-font="normal 600 2.5em/1.2 --fontFamily-googleLexend"
					/>
				</Box>
			</Box>
		</Section>
		<Section quarkly-title="CTA Button" overflow-x="hidden" overflow-y="hidden">
			<Box min-width="100px" min-height="100px" text-align="center">
				<Link
					color="--brandLight"
					text-align="center"
					background="--color-brandCta"
					transition="transform --transitionDuration-fast --transitionTimingFunction-easeInOut 0s"
					hover-transform="translateY(-4px)"
					href="https://edu.sziklaybence.com/"
					text-decoration-line="initial"
					padding="12px 24px 12px 24px"
					font="--lead"
					letter-spacing="0.5px"
					margin="18px 0px 18px 0px"
					target="_blank"
				>
					Felfedezem az Akadémiát
				</Link>
			</Box>
		</Section>
		<Section
			padding="140px 0"
			sm-padding="40px 0 40px 0"
			background="linear-gradient(0deg,rgba(72, 124, 207, 0.25) 0%,rgba(72, 124, 207, 0.15) 100%),url(https://uploads.quarkly.io/655c9b91867c86001f18116f/images/businessman-walking-towards-his-ambition.jpg?v=2023-12-14T13:40:25.350Z) left/cover"
			quarkly-title="Intro"
			sm-background="linear-gradient(0deg,rgba(72, 124, 207, 0.25) 0%,rgba(72, 124, 207, 0.15) 100%),url(https://uploads.quarkly.io/655c9b91867c86001f18116f/images/businessman-walking-towards-his-ambition.jpg?v=2023-12-11T13:47:32.952Z) center/cover"
			overflow-x="hidden"
			overflow-y="hidden"
		>
			<Override slot="SectionContent" sm-align-items="center" />
			<Box
				max-width="360px"
				padding="50px 80px 80px 50px"
				background="--color-light"
				color="--dark"
				opacity="0.8"
			>
				<Text as="h2" font="--headline2" margin="0 0 12px 0" color="--brandPrimary">
					A siker végső soron az emberi tényezőn múlik, nem a pénzügyeken
				</Text>
				<Text font="--base" color="--brandPrimary" opacity="0.8">
					Tudjuk, hogy igazából minden az emberi kapcsolatokon múlik. Ha még ezeket a sorokat olvasod, akkor te is olyan vagy, mint mi: nem csak, hogy tisztában vagy a fenti tételmondattal, de egyáltalán nem érdekelnek a hétköznapi eredmények. A legtöbb ember nem látja meg az előttük lévő potenciált, lefagynak stressz alatt és nem tesznek meg mindent a saját fejlődésükért. De te nem olyan vagy mint a legtöbb ember.
				</Text>
			</Box>
		</Section>
		<Components.FooterGlobal overflow-x="hidden" overflow-y="hidden">
			<Override slot="text1">
				Legyen szó kérdésről, megjegyzésről vagy javaslatról, mindig örömmel fogadjuk megkeresésedet.{" "}
				<Link
					href="../about/#contact"
					color="--brandSecondary"
					text-decoration-line="initial"
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					Írj nekünk itt.
				</Link>
			</Override>
			<Override slot="PromoPopup">
				<Override slot="PopupBox" />
				<Override slot="CloseButton" />
				<Override slot="PopupButton" />
				<Override slot="ModalBody" />
			</Override>
		</Components.FooterGlobal>
		<Components.QuarklycommunityKitBackToTop overflow-x="hidden" overflow-y="hidden">
			<Override slot="Icon" color="--brandCta" />
		</Components.QuarklycommunityKitBackToTop>
	</Theme>;
});